<template>
    <div class="tree-box">
        <a-input-search
            style="margin-bottom: 16px"
            v-model:value="keyWords"
            placeholder="请输入关键词以检索"
            enter-button
            @search="handleSearchTree"
        />
        <a-tree
            :tree-data="treeData"
            :fieldNames="{
                title: 'name',
            }"
            @select="handleSelectTree"
        >
            <template #title="{ name, key, code }">
                <a-tooltip>
                    <template #title>【{{ code }}】{{ name }}</template>
                    【{{ code }}】{{ name }}
                </a-tooltip>
            </template>
        </a-tree>
    </div>
</template>

<script>
import { defineComponent, watch, reactive, toRefs } from "vue";
import { apiInventoryFileCategory } from "@/api";

export default defineComponent({
    emits: ["select"],
    setup(props, { emit }) {
        const state = reactive({
            keyWords: "",
            treeData: [],
        });

        const getTreeData = async () => {
            let res = await apiInventoryFileCategory();
            if (res.status === "SUCCESS") {
                state.treeData = res?.data;
            }
        };
        getTreeData();

        const handleSearchTree = value => {
            if (!value) return getTreeData();
            let res = [];
            const cycleSearch = treeData => {
                for (let item of treeData) {
                    if (item.name === value) {
                        res = [item];
                        return res;
                    } else if (item?.children && item?.children?.length) {
                        res = cycleSearch(item?.children);
                        if (res !== undefined) {
                            return res;
                        }
                    }
                }
            };
            state.treeData = cycleSearch(state.treeData);
        };

        const handleSelectTree = (key, e) => {
            emit("select", e.selected ? e.node.code : "");
        };

        return {
            ...toRefs(state),
            handleSearchTree,
            handleSelectTree,
        };
    },
});
</script>

<style lang="less" scoped>
.tree-box {
    width: 300px;
    margin-right: 10px;
}
</style>
